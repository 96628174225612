import React from 'react'
import {
  Box,
  useMediaQuery,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'

import Arrow from './graphics/ArrowAlt'
import { TYPES } from '../utils/constants'
import ContentCard from './ContentCard'

const PrevArrow = ({ offset, onClick }) => (
  <Box
    position="absolute"
    top="50%"
    left={`-${offset}px`}
    style={{ transform: 'translateY(-50%)', cursor: 'pointer' }}
    zIndex={2}
    onClick={onClick}
  >
    <Arrow reverse />
  </Box>
)

PrevArrow.propTypes = {
  offset: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

const NextArrow = ({ offset, onClick }) => (
  <Box
    position="absolute"
    top="50%"
    right={`-${offset}px`}
    style={{ transform: 'translateY(-50%)', cursor: 'pointer' }}
    zIndex={2}
    onClick={onClick}
  >
    <Arrow />
  </Box>
)

NextArrow.propTypes = {
  offset: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

const EpisodeListCarousel = ({
  currentEpisodeId,
  currentEpisodeNumber,
  content,
  arrowOffset,
}) => {
  const smLayout = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const mdLayout = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const lgLayout = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const xlLayout = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  let slider
  let slidesToShow = 1
  const [xsArrowOffset, smArrowOffset, mdArrowOffset, lgArrowOffset, xlArrowOffset] = arrowOffset
  let arrowOffsetVal = xsArrowOffset

  if (smLayout) {
    arrowOffsetVal = smArrowOffset
  }

  if (mdLayout) {
    slidesToShow = content.length >= 2 ? 2 : 1
    arrowOffsetVal = mdArrowOffset
  }

  if (lgLayout) {
    slidesToShow = content.length >= 3 ? 3 : content.length
    arrowOffsetVal = lgArrowOffset
  }

  if (xlLayout) {
    arrowOffsetVal = xlArrowOffset
  }

  return (
    <Slider
      ref={(c) => {
        slider = c
      }}
      dots
      infinite
      initialSlide={currentEpisodeNumber || 0}
      speed={500}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
      nextArrow={
        <NextArrow offset={arrowOffsetVal} onClick={() => slider.slickNext()} />
      }
      prevArrow={
        <PrevArrow offset={arrowOffsetVal} onClick={() => slider.slickPrev} />
      }
    >
      {content.sort(
        (
          { node: { fields: { autoEpisodeNumber: a } } },
          { node: { fields: { autoEpisodeNumber: b } } },
        ) => a - b,
      ).map((item) => {
        const {
          node: { frontmatter, fields },
        } = item

        const { externalUrl } = frontmatter

        return (
          <Box key={frontmatter.id} pt={3}>
            <ContentCard
              active={
                currentEpisodeId ? frontmatter.id === currentEpisodeId : false
              }
              target={externalUrl ? '_blank' : '_self'}
              to={externalUrl || fields.slug}
              thumbnail={frontmatter.heroImg ? frontmatter.heroImg : frontmatter.thumbnail}
              type={TYPES[frontmatter.templateKey]}
              title={`${fields.autoEpisodeNumber !== null ? `${fields.autoEpisodeNumber?.toString().padStart(2, '0')}.` : ''} ${frontmatter.title}`}
              eyebrow={false}
              withCarousel
            />
          </Box>
        )
      })}
    </Slider>
  )
}

EpisodeListCarousel.propTypes = {
  currentEpisodeId: PropTypes.string,
  arrowOffset: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      templateKey: PropTypes.string,
      description: PropTypes.string,
      heroImg: PropTypes.string,
      publisher: PropTypes.string,
      mediaUrl: PropTypes.string,
    }),
  ).isRequired,
  currentEpisodeNumber: PropTypes.number,
}

EpisodeListCarousel.defaultProps = {
  currentEpisodeId: null,
  arrowOffset: [10, 0, 25, 60, 20],
  currentEpisodeNumber: null,
}

export default EpisodeListCarousel
