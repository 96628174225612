import PropTypes from 'prop-types'
import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'


const ArrowAlt = ({ reverse }) => (
  <>
    <Hidden xsUp>
      <p>
        {reverse ? 'Previous' : 'Next'}
      </p>
    </Hidden>
    <SvgIcon width="36" height="48" viewBox="0 0 36 48" fill="none" fontSize="large" style={reverse ? { transform: 'rotate(180deg)' } : null}>
      <path d="M16.1921 10.875C16.5165 10.8745 16.8282 11.037 17.0613 11.3282L25.4113 21.7207C25.6469 22.0129 25.8343 22.3634 25.9623 22.7514C26.0903 23.1393 26.1563 23.5566 26.1563 23.9783C26.1563 24.3999 26.0903 24.8172 25.9623 25.2052C25.8343 25.5931 25.6469 25.9437 25.4113 26.2359L17.0613 36.6284C16.9447 36.7851 16.8048 36.9099 16.6499 36.9952C16.4951 37.0805 16.3285 37.1247 16.1601 37.125C15.9917 37.1253 15.825 37.0818 15.67 36.997C15.5149 36.9123 15.3747 36.788 15.2578 36.6317C15.1408 36.4754 15.0494 36.2902 14.9892 36.0873C14.9289 35.8843 14.901 35.6678 14.9071 35.4506C14.9131 35.2334 14.9531 35.0201 15.0245 34.8233C15.096 34.6265 15.1974 34.4503 15.3228 34.3053L23.6178 23.9783L15.3228 13.6513C15.143 13.428 15.019 13.1404 14.9668 12.8254C14.9147 12.5104 14.9368 12.1825 15.0303 11.8838C15.1238 11.5852 15.2844 11.3295 15.4915 11.1497C15.6985 10.97 15.9425 10.8743 16.1921 10.875Z" fill="white" />
    </SvgIcon>
  </>
)

ArrowAlt.defaultProps = {
  reverse: false,
}

ArrowAlt.propTypes = {
  reverse: PropTypes.bool,
}

export default ArrowAlt
